import { Button, Dropdown, notification, Spin, Tooltip } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useDebounceValue } from '../../hooks/useDebounceValue'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import useSearchParams from '../../hooks/useSearchParams'
import { MAX_GLOBAL_RECENT_SEARCH_COUNT } from '../../utils/constants'
import DropdownOverlay from '../dropdown-overlay'
import styles from './SearchAndFilter.module.css'
import Link from 'next/link'
import { useRouter } from 'next/router'

const initialResult = {
	count: 0,
	page: 0,
	results: []
}

const pageSize = 50

const SearchAndFilter = ({
	localStorageKey,
	tooltipTitle,
	searchMethod,
	searchErrorMessage,
	getResultHref,
	getResultLabel,
	FilterDropdownOverlay,
	onSearch,
	onFilter,
	isSearchAppliedPredicate = () => true,
	getDefaultParams = () => {}
}) => {
	const router = useRouter()
	const { followUpDate, tab, type } = router.query
	const { isSearchApplied, searchParams, applySearch, applyFilter, clearSearch } = useSearchParams()
	const [recentSearch, setRecentSearch] = useLocalStorage(localStorageKey, [])
	const [{ count, page, results }, setSearchResult] = useState(initialResult)
	const totalPages = useMemo(() => Math.floor((count + pageSize - 1) / pageSize), [count])
	const [searchTerm, setSearchTerm] = useState(searchParams.searchTerm || '')
	const debouncedSearchTerm = useDebounceValue(searchTerm, 600)
	const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(false)
	const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false)
	const [isSearching, setIsSearching] = useState(false)

	useEffect(() => {
		const updateSearchDropdownState = e => {
			const shouldDropdownStayOpen =
			e.target.closest(`.${styles.searchDropdown}`) ||
			e.target.closest(`.${styles.input}`) ||
			e.target.closest(`.${styles.closeButton}`)
			!shouldDropdownStayOpen && setIsSearchDropdownOpen(false)
		}

		document.addEventListener('click', updateSearchDropdownState)
		return () => document.removeEventListener('click', updateSearchDropdownState)
	}, [])

	useEffect(() => {
		if (searchTerm.length > debouncedSearchTerm.length) {
			setIsSearching(true)
		} else {
			setIsSearching(false)
		}
		if (!searchTerm) {
			setSearchResult(initialResult)
		}
	}, [searchTerm, debouncedSearchTerm])

	useEffect(() => {
		if (!debouncedSearchTerm) {
			return setSearchResult(initialResult)
		}
		search()
	}, [debouncedSearchTerm, tab, type])

	useEffect(() => {
		if (followUpDate) {
			setIsFilterDropdownOpen(true)
		}
	}, [followUpDate])

	useEffect(() => {
		const params = getDefaultParams()
		if (params) {
			applyFilter(params)
		}
	}, [])

	const search = async (page = 0) => {
		try {
			setIsSearching(true)
			setSearchResult(initialResult)
			const { data } = await searchMethod({
				...searchParams,
				searchTerm,
				page,
				pageSize
			})
			setSearchResult({
				count: +data.count,
				page: data.page,
				results: data.results
			})
		} catch ({ message }) {
			notification.error({
				message: searchErrorMessage,
				description: message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsSearching(false)
		}
	}

	const addToRecentSearch = () => {
		if (!searchTerm) {
			return
		}

		const newRecentSearch = new Set([searchTerm, ...recentSearch].slice(0, MAX_GLOBAL_RECENT_SEARCH_COUNT))
		setRecentSearch([...newRecentSearch])
	}

	const renderSearchOverlay = () => {
		return (
			<DropdownOverlay className={styles.searchDropdown}>
				<div className={styles.searchContainer}>
					{
						isSearching ?
							<div className={styles.spinner}>
								<Spin size='large' />
							</div> :
							results.length ?
								<>
									<p className={styles.dropdownTitle}>
										Showing {count} result{count > 1 && 's'} for <em>{searchTerm}</em>
									</p>
									<div className={styles.resultsContainer}>
										<ul className={styles.results}>
											{
												results.map((result) => {
													const href = getResultHref && getResultHref(result)
													return href ?
														<Link key={result.id} href={href}>
															<a className={styles.result}>
																{getResultLabel(result)}
															</a>
														</Link> :
														<li key={result.id} className={styles.result}>
															{getResultLabel(result)}
														</li>
												})
											}
										</ul>
										{
											count > pageSize &&
											<div className={styles.pagination}>
												<Button
													className={styles.paginationButton}
													disabled={page <= 0}
													onClick={(e) => {
														e.stopPropagation()
														search(page - 1)
													}}
												>
													<img src='/img/arrow-right.svg' alt='Arrow Right' />
												</Button>
												<span className={styles.resultCount}>
													{`${+page + 1}`}
												</span>
												<Button
													className={styles.paginationButton}
													disabled={(page + 1) >= totalPages}
													onClick={(e) => {
														e.stopPropagation()
														search(page + 1)
													}}
												>
													<img src='/img/arrow-right.svg' alt='Arrow Right' />
												</Button>
											</div>
										}
									</div>
								</> :
								searchTerm && !isSearching && !results.length ?
									<>
										<p className={styles.dropdownTitle}>
											Showing results for <em>{searchTerm}</em>
										</p>
										<div className={styles.noData}>
											<span className={styles.iconContainer}>
												<img src='/img/file-error-dark.svg' alt='File icon' />
											</span>
											<p className={styles.noDataTitle}>No Data Found!</p>
											<p className={styles.noDataDescription}>No search results found.</p>
										</div>
									</> :
									<>
										{
											recentSearch.length ?
												<>
													<p className={styles.dropdownTitle}>Recent Search</p>
													<div className={styles.resultsContainer}>
														<ul className={styles.results}>
															{
																recentSearch.map((searchTerm) => {
																	return (
																		<li
																			style={{ cursor: 'pointer' }}
																			key={searchTerm}
																			className={styles.result}
																			onClick={(e) => {
																				e.stopPropagation()
																				setSearchTerm(searchTerm)
																			}}
																		>
																			{searchTerm}
																			<button
																				className={`${styles.removeResultButton}`}
																				type='button'
																				onClick={(e) => {
																					e.stopPropagation()
																					setRecentSearch(recentSearch.filter((recentSearch) => recentSearch !== searchTerm))
																				}}
																			>
																				<img src='/img/close-3-fill.svg' alt='Close icon' />
																			</button>
																		</li>
																	)
																})
															}
														</ul>
													</div>
												</> :
												<div className={styles.noRecentSearch}>
													<p className={styles.dropdownTitle}>No Recent Search</p>
												</div>
										}
									</>
					}
				</div>
			</DropdownOverlay>
		)
	}

	return (
		<>
			<form
				className={styles.form}
				onSubmit={(e) => {
					e.preventDefault()
					setIsSearchDropdownOpen(false)
					applySearch(searchTerm)
					addToRecentSearch()
				}}
			>
				<Dropdown
					overlay={renderSearchOverlay}
					visible={isSearchDropdownOpen}
					getPopupContainer={() => document.querySelector(`.${styles.inputContainer}`)}
				>
					<div className={styles.inputContainer}>
						<Tooltip title={tooltipTitle} mouseEnterDelay={1}>
							<input
								className={styles.input}
								type='text'
								placeholder='Search'
								value={searchTerm}
								onChange={({ target: { value } }) => setSearchTerm(value)}
								onFocus={() => setIsSearchDropdownOpen(true)}
								onClick={() => setIsSearchDropdownOpen(true)}
							/>
						</Tooltip>
						<Button
							htmlType='submit'
							className={styles.searchButton}
							disabled={!searchTerm}
							onClick={() => {
								applySearch(searchTerm)
								addToRecentSearch()
								onSearch?.(searchTerm)
							}}
						>
							<img src='/img/search-white.svg' alt='Search icon' />
						</Button>
						<button
							className={`${styles.closeButton} ${(searchTerm || searchParams.searchTerm) && styles.closeButtonVisible}`}
							type='button'
							onClick={() => {
								setSearchTerm('')
								setSearchResult(initialResult)
								clearSearch()
							}}
						>
							<img src='/img/close-3-fill.svg' alt='Close icon' />
						</button>
					</div>
				</Dropdown>
				<Dropdown
					placement='bottomRight'
					trigger={['click']}
					overlay={
						<FilterDropdownOverlay
							isFilterDropdownOpen={isFilterDropdownOpen}
							setIsFilterDropdownOpen={setIsFilterDropdownOpen}
							onFilter={onFilter}
						/>
					}
					visible={isFilterDropdownOpen}
					onVisibleChange={setIsFilterDropdownOpen}
					getPopupContainer={() => document.querySelector(`.${styles.form}`)}
				>
					<Button className={`${styles.filterButton} ${isSearchApplied && isSearchAppliedPredicate(searchParams) && styles.searchApplied}`}>
						{
							isFilterDropdownOpen ?
								<svg className={`${styles.closeIcon} ${isSearchApplied && isSearchAppliedPredicate(searchParams) && styles.closeIconWhite}`}>
									<use href='/img/close-3.svg#icon' />
								</svg> :
								<svg className={styles.filterIcon}>
									<use href='/img/filter-2.svg#icon' />
								</svg>
						}
						<span className={styles.filterText}>Filter</span>
						<span className={`${styles.activeFilterIndicator} ${isSearchApplied && isSearchAppliedPredicate(searchParams) && styles.activeFilterIndicatorVisible}`} />
					</Button>
				</Dropdown>
			</form>
		</>
	)
}

export default SearchAndFilter
