import { Modal, Typography, notification } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { DeliveryPartners } from '../../services/api/delivery-partner'
import { SalesOrders } from '../../services/api/sales-orders'
import { PartnerType, paths, localStorageKeys } from '../../utils/constants'
import CustomSelectSecondary from '../custom-select-secondary'
import Input from '../input'
import CustomTable from '../table'
import styles from './UpdateOrdersDeliveryPartnerModal.module.css'
import Link from 'next/link'
import DeliveryPartnerOption from '../delivery-partner-option'
import { determineDeliveryCharge, convertDeliveryAreaToDeliveryAreaOption, convertDeliveryAreaOptionToDeliveryArea } from '../../utils/delivery-partners'
import DeliveryAreaSelect from '../delivery-area-select'
import DeliveryAreaSelectRedX from '../delivery-area-select-redx'
import DeliveryAreaSelectECourier from '../delivery-area-select-e-courier'
import DeliveryAreaSelectPidex from '../delivery-area-select-pidex'
import DeliveryAreaSelectMoveX from '../delivery-area-select-movex'
import DeliveryAreaSelectTCSCourier from '../delivery-area-select-tcs-courier'
import DeliveryAreaSelectRiderCourier from '../delivery-area-select-rider-courier'
import DeliveryAreaSelectPostEx from '../delivery-area-select-post-ex'
import DeliveryAreaSelectMpCourier from '../delivery-area-select-mp-courier'
import DeliveryAreaSelectLeopards from '../delivery-area-select-leopards'
import DeliveryAreaSelectBlueEx from '../delivery-area-select-blue-ex'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { useSelector } from 'react-redux'
import DeliveryAreaSelectPaperfly from '../delivery-area-select-paperfly'
import PickUpOutletSelectPandago from '../pick-up-outlet-select-pandago'
import DeliveryAreaSelectXpressInTown from '../delivery-area-select-xpress-in-town'

const modalButtonProps = {
	size: 'large',
	style: {
		borderRadius: 0
	}
}

const partnerWithNoWarehouseLookupKey = 'partners_with_no_warehouse'

const UpdateOrdersDeliveryPartnerModal = ({
	visible,
	salesOrderIds = [],
	onCancel,
	onComplete
}) => {
	const { preferences } = useSelector(state => state.authReducer)
	const [salesOrders, setSalesOrders] = useState([])
	const [deliveryPartners, setDeliveryPartners] = useState([])
	const [locationDeliveryPartnersMap, setLocationDeliveryPartnersMap] = useState({})
	const [isLoadingDeliveryPartners, setIsLoadingDeliveryPartners] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isUpdating, setIsUpdating] = useState(false)
	const [selectedSalesOrderIds, setSelectedSalesOrderIds] = useState([])
	const [selectedWarehouse, setSelectedWarehouse] = useState(null)
	const [selectedWarehouseDeliveryPartnerOptions, setSelectedWarehouseDeliveryPartnerOptions] = useState([])
	const [selectedDeliveryPartner, setSelectedDeliveryPartner] = useState(null)
	const [lastDeliveryAddressToDeliveryAreaMapping, setLastDeliveryAddressToDeliveryAreaMapping] = useLocalStorage(localStorageKeys.LAST_DELIVERY_ADDRESS_TO_DELIVERY_AREA_MAPPING, {})

	useEffect(() => {
		if (salesOrders.length) {
			getDeliveryPartners()
		}
	}, [salesOrders.length])

	useEffect(() => {
		getSalesOrders()
	}, [salesOrderIds])

	useEffect(() => {
		setSelectedDeliveryPartner(null)
		if (selectedWarehouse) {
			setSelectedWarehouseDeliveryPartnerOptions(
				locationDeliveryPartnersMap[selectedWarehouse]?.length
					? locationDeliveryPartnersMap[selectedWarehouse].concat(
						locationDeliveryPartnersMap[partnerWithNoWarehouseLookupKey] || []
					)
					: locationDeliveryPartnersMap[partnerWithNoWarehouseLookupKey] || []
			)
		} else {
			setSelectedWarehouseDeliveryPartnerOptions([])
			setSelectedSalesOrderIds([])
		}
	}, [selectedWarehouse])

	useEffect(() => {
		if (selectedDeliveryPartner) {
			updateSalesOrdersDeliveryPartner()
		}
	}, [selectedDeliveryPartner])

	useEffect(() => {
		if (!selectedSalesOrderIds.length) {
			setSelectedDeliveryPartner(null)
		}
	}, [selectedSalesOrderIds.length])

	const updateSalesOrdersDeliveryPartner = () => {
		const deliveryPartner = deliveryPartners.find(({ id }) => id === selectedDeliveryPartner)
		fetchDeliveryCharges(deliveryPartner)
		const filteredSalesOrders = salesOrders.filter(({ id }) => selectedSalesOrderIds.includes(id))
		const salesOrdersGroupedByLocation = filteredSalesOrders.reduce((acc, cur) => {
			if (acc[cur.locationId]) {
				acc[cur.locationId].push(cur)
				return acc
			}
			acc[cur.locationId] = [cur]
			return acc
		}, {})
		Object.entries(salesOrdersGroupedByLocation).forEach(([locationId, salesOrders]) => {
			let cachedDeliveryArea = null
			if (
				lastDeliveryAddressToDeliveryAreaMapping[deliveryPartner?.type] &&
				lastDeliveryAddressToDeliveryAreaMapping[deliveryPartner?.type][locationId]
			) {
				cachedDeliveryArea = convertDeliveryAreaOptionToDeliveryArea(lastDeliveryAddressToDeliveryAreaMapping[deliveryPartner?.type][locationId], deliveryPartner?.type)
			}
			updateSalesOrderState(
				salesOrders.map(({ id }) => id),
				{
					preferredDeliveryPartner: selectedDeliveryPartner,
					deliveryPartner,
					deliveryArea: cachedDeliveryArea
				}
			)
		})
	}

	const updateSalesOrderState = (salesOrderIds = [], data = {}) => {
		setSalesOrders(prevSalesOrders =>
			prevSalesOrders.map(order => {
				return (
					salesOrderIds.includes(order.id)
						? {
							...order,
							...data
						}
						: order
				)
			})
		)
	}

	const onChangePickUpArea = (orderId, pickUpAreaOption) => {
		setSalesOrders(prevOrders =>
			prevOrders.map(order => {
				if (order.id === orderId) {
					const updatedOrder = {
						...order
					}
					if (!updatedOrder.metadata) {
						updatedOrder.metadata = {}
					}
					updatedOrder.metadata.deliveryOutlet = pickUpAreaOption ? {
						id: pickUpAreaOption.value,
						name: pickUpAreaOption.label,
						address: pickUpAreaOption.description
					} : null
					return updatedOrder

				} else {
					return order
				}
			})
		)
	}

	const orderColumns = [
		{
			title: 'Invoice No.',
			key: 'invoiceNumber',
			render: ({ internalId, id }) => {
				return (
					<Link href={`${paths.SALES_ORDERS}/${id}`}>
						<a>{internalId}</a>
					</Link>
				)
			}
		},
		{
			title: 'Pickup Address',
			key: 'pickUpLocation',
			render: ({ pickUpLocation }) => {
				return (
					`${pickUpLocation.label || pickUpLocation.address}`
				)
			}
		},
		{
			title: 'Location',
			key: 'location',
			render: ({ location }) => {
				return (
					`${location.label || location.address}`
				)
			}
		},
		{
			title: 'Delivery Partner',
			key: 'deliveryPartner',
			render: ({ id, preferredDeliveryPartner, deliveryPartner, pickUpLocationId, locationId, isDeliveryFree, integrationId }) => {
				const partners = locationDeliveryPartnersMap[pickUpLocationId] || []
				const partnersWithNoWarehouse = locationDeliveryPartnersMap[partnerWithNoWarehouseLookupKey] || []
				const deliveryPartners = [...partners, ...partnersWithNoWarehouse]
				if (preferredDeliveryPartner && !deliveryPartners.find(dp => dp.id === preferredDeliveryPartner)) {
					deliveryPartners.push(deliveryPartner)
				}
				return (
					<CustomSelectSecondary
						title='Select Partner'
						optionLabelProp='label'
						style={{ width: 200 }}
						loading={isLoadingDeliveryPartners || isLoading}
						options={deliveryPartners.map(({ id, name, type }) => ({ value: id, label: name, type }))}
						value={deliveryPartners.length ? preferredDeliveryPartner : null}
						onChange={async value => {
							try {
								const deliveryPartner = deliveryPartners.find((p) => p.id === value)
								let overrideDeliveryCharge = !(integrationId && preferences.websiteOrdersDeliveryFeeEnabled) && !(preferences.manualOrdersDeliveryFeeEnabled && !integrationId)
								let deliveryCharge = 0
								if (!isDeliveryFree) {
									if (overrideDeliveryCharge) {
										deliveryCharge = await getDeliveryCharge(locationId, deliveryPartner?.charges || [], pickUpLocationId, deliveryPartner)
									}
								}
								const salesOrder = salesOrders.find(salesOrder => salesOrder.id === id)
								let cachedDeliveryArea = null
								if (
									lastDeliveryAddressToDeliveryAreaMapping[deliveryPartner?.type] &&
									lastDeliveryAddressToDeliveryAreaMapping[deliveryPartner?.type][salesOrder?.locationId]
								) {
									cachedDeliveryArea = convertDeliveryAreaOptionToDeliveryArea(lastDeliveryAddressToDeliveryAreaMapping[deliveryPartner?.type][salesOrder?.locationId], deliveryPartner?.type)
								}
								if (overrideDeliveryCharge) {
									updateSalesOrderState([id], {
										preferredDeliveryPartner: value,
										deliveryPartner: deliveryPartner,
										deliveryCharge: deliveryCharge,
										deliveryArea: cachedDeliveryArea
									})
								} else {
									updateSalesOrderState([id], {
										preferredDeliveryPartner: value,
										deliveryPartner: deliveryPartner,
										deliveryArea: cachedDeliveryArea
									})
								}
								setSelectedDeliveryPartner(null)
							} catch (e) {
								console.error(e)
							}
						}}
						optionRender={DeliveryPartnerOption}
						popupContainer={() => document.querySelector(`.${styles.modal}`)}
					/>
				)
			}
		},
		{
			title: 'Pick Up Area',
			key: 'pickUpArea',
			width: 280,
			render: ({ id, deliveryPartner, metadata }) => {
				const deliveryPartnerType = deliveryPartner?.type
				const outlet = metadata?.deliveryOutlet ? {
					value: metadata?.deliveryOutlet.id,
					label: metadata?.deliveryOutlet.name,
					description: metadata?.deliveryOutlet.address
				} : null
				switch (deliveryPartnerType) {
					case PartnerType.PANDAGO:
						return (
							<PickUpOutletSelectPandago
								deliveryPartnerId={deliveryPartner?.id}
								title='Pick Up Outlet'
								value={outlet}
								defaultValue={ outlet ? null : { value: deliveryPartner?.storeId }}
								onChange={(option) => onChangePickUpArea(id, option)}
							/>
						)
					default:
						return 'N/A'
				}
			}
		},
		{
			title: 'Delivery Area',
			key: 'deliveryArea',
			render: ({ id, deliveryArea, deliveryPartner, location }) => {
				const deliveryPartnerType = deliveryPartner?.type
				const deliveryAreaOption = convertDeliveryAreaToDeliveryAreaOption(deliveryArea, deliveryPartnerType)

				switch (deliveryPartnerType) {
					case PartnerType.MOVEX:
						return (
							<DeliveryAreaSelectMoveX
								title='Delivery Area'
								deliveryPartnerId={deliveryPartner.id}
								error={!deliveryAreaOption?.area}
								value={deliveryAreaOption}
								onChange={({ area }) => {
									updateSalesOrderState([id], {
										deliveryArea: area
											? {
												areaId: area?.value || null,
												areaName: area?.label || null
											}
											: null
									})
								}}
							/>
						)
					case PartnerType.PAPERFLY:
						return (
							<DeliveryAreaSelectPaperfly
								deliveryPartnerId={deliveryPartner}
								title='Delivery Area'
								error={!deliveryAreaOption?.zone || !deliveryAreaOption?.area}
								value={deliveryAreaOption}
								onChange={({ zone, area }) => {
									updateSalesOrderState([id], {
										deliveryArea: zone
											? {
												zoneName: zone?.value,
												areaName: area?.value
											}
											: null
									})
								}}
							/>
						)
					case PartnerType.REDX:
						return (
							<DeliveryAreaSelectRedX
								title='Delivery Area'
								deliveryPartnerId={deliveryPartner.id}
								error={
									!deliveryAreaOption?.division
									|| !deliveryAreaOption?.district
									|| !deliveryAreaOption?.area
								}
								value={deliveryAreaOption}
								onChange={({ division, district, area }) => {
									updateSalesOrderState([id], {
										deliveryArea: division
											? {
												divisionId: division.value,
												divisionName: division.label,
												districtId: district?.value || null,
												districtName: district?.label || null,
												areaId: area?.value || null,
												areaName: area?.label || null
											}
											: null
									})
								}}
							/>
						)
					case PartnerType.E_COURIER:
						return (
							<DeliveryAreaSelectECourier
								title='Delivery Area'
								deliveryPartnerId={deliveryPartner.id}
								error={
									!deliveryAreaOption?.city
									|| !deliveryAreaOption?.area
								}
								value={deliveryAreaOption}
								onChange={({ city, area, thana, postCode }) => {
									updateSalesOrderState([id], {
										deliveryArea: city
											? {
												cityId: city.value,
												cityName: city.label,
												areaId: area?.value || null,
												areaName: area?.label || null,
												thanaId: thana?.value || null,
												thanaName: thana?.label || null,
												postCodeId: postCode?.value || null,
												postCodeName: postCode?.label || null
											}
											: null
									})
								}}
							/>
						)
					case PartnerType.PIDEX:
						return (
							<DeliveryAreaSelectPidex
								title='Delivery Area'
								deliveryPartnerId={deliveryPartner.id}
								error={
									deliveryAreaOption?.city
									&& !deliveryAreaOption?.zone
								}
								value={deliveryAreaOption}
								onChange={({ city, zone, area }) => {
									updateSalesOrderState([id], {
										deliveryArea: city
											? {
												cityId: city.value,
												cityName: city.label,
												zoneId: zone?.value || null,
												zoneName: zone?.label || null,
												areaId: area?.value || null,
												areaName: area?.label || null
											}
											: null
									})
								}}
							/>
						)
					case PartnerType.TCS_COURIER:
						return (
							<DeliveryAreaSelectTCSCourier
								deliveryPartnerId={deliveryPartner.id}
								title='Delivery Area'
								error={!deliveryAreaOption?.originCity || !deliveryAreaOption?.city}
								value={deliveryAreaOption}
								onChange={({ originCity, city }) => {
									updateSalesOrderState([id], {
										deliveryArea: originCity || city
											? {
												originCityId: originCity?.value,
												originCityName: originCity?.label,
												cityId: city?.value,
												cityName: city?.label
											}
											: null
									})
								}}
							/>
						)
					case PartnerType.RIDER_COURIER:
						return (
							<DeliveryAreaSelectRiderCourier
								deliveryPartnerId={deliveryPartner.id}
								title='Delivery Area'
								error={!deliveryAreaOption?.originCity || !deliveryAreaOption?.city}
								value={deliveryAreaOption}
								onChange={({ originCity, city, area }) => {
									updateSalesOrderState([id], {
										deliveryArea: originCity || city
											? {
												originCityId: originCity?.value,
												originCityName: originCity?.label,
												cityId: city?.value,
												cityName: city?.label,
												areaId: area?.value,
												areaName: area?.label
											}
											: null
									})
								}}
							/>
						)
					case PartnerType.POST_EX:
						return (
							<DeliveryAreaSelectPostEx
								deliveryPartnerId={deliveryPartner.id}
								title='Delivery Area'
								error={!deliveryAreaOption?.pickupAddress || !deliveryAreaOption?.city}
								value={deliveryAreaOption}
								onChange={({ pickupAddress, city }) => {
									updateSalesOrderState([id], {
										deliveryArea: pickupAddress || city
											? {
												pickupAddressId: pickupAddress?.value,
												pickupAddressName: pickupAddress?.label,
												cityId: city?.value,
												cityName: city?.label
											}
											: null
									})
								}}
							/>
						)
					case PartnerType.MP_COURIER:
						return (
							<DeliveryAreaSelectMpCourier
								deliveryPartnerId={deliveryPartner.id}
								title='Delivery Area'
								error={!deliveryAreaOption?.city}
								value={deliveryAreaOption}
								onChange={({ city, pickupAddress }) => {
									updateSalesOrderState([id], {
										deliveryArea: city
											? {
												cityId: city.value,
												cityName: city.label,
												pickupAddressId: pickupAddress?.value,
												pickupAddressName: pickupAddress?.label
											}
											: null
									})
								}}
							/>
						)
					case PartnerType.LEOPARDS:
						return (
							<DeliveryAreaSelectLeopards
								deliveryPartnerId={deliveryPartner.id}
								title='Delivery Area'
								error={!deliveryAreaOption?.city}
								value={deliveryAreaOption}
								onChange={({ city }) => {
									updateSalesOrderState([id], {
										deliveryArea: city
											? {
												cityId: city.value,
												cityName: city.label
											}
											: null
									})
								}}
							/>
						)
					case PartnerType.BLUE_EX:
						return (
							<DeliveryAreaSelectBlueEx
								deliveryPartnerId={deliveryPartner.id}
								title='Delivery Area'
								error={!deliveryAreaOption?.city || !deliveryAreaOption?.serviceType}
								value={deliveryAreaOption}
								onChange={({ city, serviceType }) => {
									updateSalesOrderState([id], {
										deliveryArea: city || serviceType
											? {
												cityId: city?.value || null,
												cityName: city?.label || null,
												serviceTypeId: serviceType?.value || null,
												serviceTypeName: serviceType?.label || null
											}
											: null
									})
								}}
							/>
						)
					case PartnerType.XPRESS_IN_TOWN:
						return (
							<DeliveryAreaSelectXpressInTown
								title='Delivery Area'
								deliveryPartnerId={deliveryPartner.id}
								error={!deliveryAreaOption?.division || !deliveryAreaOption?.city || !deliveryAreaOption?.zone || !deliveryAreaOption?.area}
								value={deliveryAreaOption}
								onChange={({ division, city, zone, area }) => {
									updateSalesOrderState([id], {
										deliveryArea: division
											? {
												divisionId: division?.value || null,
												divisionName: division?.label || null,
												cityId: city?.value || null,
												cityName: city?.label || null,
												zoneId: zone?.value || null,
												zoneName: zone?.label || null,
												areaId: area?.value || null,
												areaName: area?.label || null
											}
											: null
									})
								}}
							/>
						)
					default:
						return (
							<DeliveryAreaSelect
								address={location?.address}
								title='Delivery Area'
								deliveryPartnerId={
									deliveryPartner?.type === PartnerType.PATHAO
										? deliveryPartner.id
										: null
								}
								disabled={deliveryPartner?.type !== PartnerType.PATHAO}
								value={
									deliveryAreaOption
										? deliveryAreaOption
										: deliveryArea
											? {
												city: {
													value: deliveryArea.cityId,
													label: deliveryArea.cityName
												},
												zone: {
													value: deliveryArea.zoneId,
													label: deliveryArea.zoneName
												},
												area: {
													value: deliveryArea.areaId,
													label: deliveryArea.areaName
												}
											}
											: null
								}
								onChange={({ city, zone, area }) => {
									updateSalesOrderState([id], {
										deliveryArea: {
											cityId: city?.value || null,
											cityName: city?.label || null,
											zoneId: zone?.value || null,
											zoneName: zone?.label || null,
											areaId: area?.value || null,
											areaName: area?.label || null
										}
									})
								}}
								style={{ width: 280 }}
								popupContainer={() => document.querySelector(`.${styles.modal}`)}
							/>
						)
				}
			}
		},
		{
			title: 'Delivery Fee',
			key: 'deliveryCharge',
			render: ({ id, deliveryCharge }) => {
				return (
					<Input
						inputClassName='delivery-fee-input'
						onChange={value => updateSalesOrderState([id], { deliveryCharge: isNaN(+value) ? 0 : +value })}
						value={deliveryCharge}
						min={0}
						type='currency'
						disableCurrency={true}
					/>
				)
			}
		}
	]

	const fetchDeliveryCharges = async deliveryPartner => {
		setIsLoading(true)
		try {
			const deliveryCharges = await Promise.all(
				selectedSalesOrderIds.map(async salesOrderId => {
					const { locationId, isDeliveryFree, pickUpLocationId } = salesOrders.find(({ id }) => id === salesOrderId)
					if (isDeliveryFree) {
						return 0
					}
					return await getDeliveryCharge(locationId, deliveryPartner?.charges || [], pickUpLocationId, deliveryPartner)
				})

			)
			setSalesOrders(prevSalesOrders =>
				prevSalesOrders.map(prevSalesOrder => {
					const selectedSalesOrderIdIndex = selectedSalesOrderIds.findIndex(selectedSalesOrderId => selectedSalesOrderId == prevSalesOrder.id)
					if (selectedSalesOrderIdIndex >= 0) {
						if (!(prevSalesOrder.integrationId && preferences.websiteOrdersDeliveryFeeEnabled) && !(preferences.manualOrdersDeliveryFeeEnabled && !prevSalesOrder.integrationId)) {
							return {
								...prevSalesOrder,
								deliveryCharge: deliveryCharges[selectedSalesOrderIdIndex]
							}
						} else {
							return prevSalesOrder
						}
					}
					return prevSalesOrder
				})
			)
		} catch (e) {
			notification.error({
				message: 'Unable To Get Delivery Charges',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoading(false)
		}
	}

	const getSalesOrders = async () => {
		try {
			setIsLoading(true)
			const { data } = await SalesOrders.multiple(salesOrderIds)
			setSalesOrders(data)
		} catch (e) {
			notification.error({
				message: 'Unable to get sales orders',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoading(false)
		}
	}

	const getDistinctPickUpLocationIdsFromOrders = () => {
		const pickUpLocationIds = new Set()
		salesOrders.forEach(salesOrder => {
			if (salesOrder.pickUpLocationId) {
				pickUpLocationIds.add(salesOrder.pickUpLocationId)
			}
		})
		return Array.from(pickUpLocationIds)
	}

	const setLocationWiseDeliveryPartners = (partners = []) => {
		const locationDeliveryPartnersMap = {}
		partners.forEach((partner) => {
			if (partner.warehouseId) {
				if (locationDeliveryPartnersMap[partner.warehouseId]) {
					locationDeliveryPartnersMap[partner.warehouseId].push(partner)
				} else {
					locationDeliveryPartnersMap[partner.warehouseId] = [partner]
				}
			} else {
				if (locationDeliveryPartnersMap[partnerWithNoWarehouseLookupKey]) {
					locationDeliveryPartnersMap[partnerWithNoWarehouseLookupKey].push(partner)
				} else {
					locationDeliveryPartnersMap[partnerWithNoWarehouseLookupKey] = [partner]
				}
			}
		})
		setLocationDeliveryPartnersMap(locationDeliveryPartnersMap)
	}

	const getDeliveryPartners = async () => {
		try {
			setIsLoadingDeliveryPartners(true)
			const { data } = await DeliveryPartners.index({
				page: -1,
				active: true,
				warehouseIds: getDistinctPickUpLocationIdsFromOrders()
			})
			setDeliveryPartners(data.results)
			setLocationWiseDeliveryPartners(data.results)
		} catch (e) {
			notification.error({
				message: 'Couldn\'t get delivery partners',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingDeliveryPartners(false)
		}
	}

	const updateOrdersDeliveryPartner = async () => {
		try {
			setIsUpdating(true)
			const payloads = tableData.map(({ id, preferredDeliveryPartner, deliveryCharge, deliveryPartner, deliveryArea, location, metadata }) => {
				const payload = {
					id,
					preferredDeliveryPartner,
					deliveryArea,
					deliveryCharge,
					deliveryPartner,
					location,
					metadata
				}
				switch (deliveryPartner?.type) {
					case PartnerType.REDX:
						payload.deliveryArea = deliveryArea?.divisionId && deliveryArea?.districtId && deliveryArea?.areaId
							? deliveryArea
							: null
						break
					case PartnerType.E_COURIER:
						payload.deliveryArea = deliveryArea?.cityId && deliveryArea?.areaId && deliveryArea?.thanaId && deliveryArea?.postCodeId
							? deliveryArea
							: null
						break
					case PartnerType.PIDEX:
						payload.deliveryArea = deliveryArea?.cityId && deliveryArea?.zoneId
							? deliveryArea
							: null
						break
					case PartnerType.TCS_COURIER:
					case PartnerType.RIDER_COURIER:
						payload.deliveryArea = deliveryArea?.originCityId && deliveryArea?.cityId
							? deliveryArea
							: null
						break
					case PartnerType.POST_EX:
						payload.deliveryArea = deliveryArea?.pickupAddressId && deliveryArea?.cityId
							? deliveryArea
							: null
						break
					case PartnerType.MP_COURIER:
					case PartnerType.LEOPARDS:
						payload.deliveryArea = deliveryArea?.cityId
							? deliveryArea
							: null
						break
					case PartnerType.BLUE_EX:
						payload.deliveryArea = deliveryArea?.cityId && deliveryArea?.serviceTypeId
							? deliveryArea
							: null
						break
					case PartnerType.MOVEX:
						payload.deliveryArea = deliveryArea?.areaId
							? deliveryArea
							: null
						break
					case PartnerType.XPRESS_IN_TOWN:
						payload.deliveryArea = deliveryArea?.divisionId && deliveryArea?.cityId && deliveryArea?.zoneId && deliveryArea?.areaId
							? deliveryArea
							: null
						break
					case PartnerType.PAPERFLY:
						payload.deliveryArea = deliveryArea?.areaName
							? deliveryArea
							: null
				}
				return payload
			})
			const { data } = await SalesOrders.updateOrders(payloads)
			if (data.errors.length > 0) {
				throw new Error([...new Set(data.errors)].toString())
			}
			notification.success({
				message: 'Updated Order\'s Delivery Partner',
				description: 'Successfully updated order\'s delivery partner.',
				placement: 'bottomLeft'
			})
			payloads.filter(({ deliveryArea }) => deliveryArea).forEach(({ deliveryPartner, location, deliveryArea }) => {
				updateLastDeliveryAddressToDeliveryAreaMapping(deliveryPartner?.type, location?.id, deliveryArea)
			})
			onCancel()
			onComplete()
		} catch (e) {
			notification.error({
				message: 'Unable to Update Order\'s Delivery Partner',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdating(false)
		}
	}

	const getDeliveryCharge = async (locationId, charges = [], warehouseId, selectedDeliveryPartner) => {
		try {
			if (!locationId || !charges.length) {
				return 0
			}
			const result = await determineDeliveryCharge(selectedDeliveryPartner?.id, warehouseId, locationId)
			return result.deliveryCharge
		} catch (e) {
			console.error(e)
			return 0
		}
	}

	const tableData = useMemo(() => {
		return selectedWarehouse ?
			salesOrders
				.map((order) => ({
					...order,
					key: order.id
				}))
				.filter((order) => order.pickUpLocationId === selectedWarehouse) :
			salesOrders
				.map((order) => ({
					...order,
					key: order.id
				}))
	}, [salesOrders, selectedWarehouse])

	const isUpdateButtonDisabled = useMemo(() => {
		return tableData.some(({ preferredDeliveryPartner, deliveryPartner, deliveryArea, metadata }) => {
			switch (deliveryPartner?.type) {
				case PartnerType.REDX:
					return !preferredDeliveryPartner
						|| !deliveryArea?.divisionId
						|| !deliveryArea?.districtId
						|| !deliveryArea?.areaId
				case PartnerType.E_COURIER:
					return !preferredDeliveryPartner
						|| !deliveryArea?.cityId
						|| !deliveryArea?.areaId
				case PartnerType.PATHAO:
					return !preferredDeliveryPartner
                        || !deliveryArea?.cityId
                        || !deliveryArea?.zoneId
				case PartnerType.PIDEX:
					return !preferredDeliveryPartner
						|| (deliveryArea?.cityId && !deliveryArea?.zoneId)
				case PartnerType.TCS_COURIER:
				case PartnerType.RIDER_COURIER:
					return !preferredDeliveryPartner || !deliveryArea?.originCityId || !deliveryArea?.cityId
				case PartnerType.POST_EX:
					return !preferredDeliveryPartner || !deliveryArea?.pickupAddressId || !deliveryArea?.cityId
				case PartnerType.MP_COURIER:
				case PartnerType.LEOPARDS:
					return !preferredDeliveryPartner || !deliveryArea?.cityId
				case PartnerType.BLUE_EX:
					return !preferredDeliveryPartner
						|| !deliveryArea?.cityId
						|| !deliveryArea?.serviceTypeId
				case PartnerType.MOVEX:
					return !preferredDeliveryPartner || !deliveryArea?.areaId
				case PartnerType.PAPERFLY:
					return !preferredDeliveryPartner || !deliveryArea?.areaName || !deliveryArea?.zoneName
				case PartnerType.PANDAGO:
					return !preferredDeliveryPartner || !metadata?.deliveryOutlet
				case PartnerType.XPRESS_IN_TOWN:
					return !preferredDeliveryPartner
                    || !deliveryArea?.divisionId
                    || !deliveryArea?.cityId
                    || !deliveryArea?.zoneId
                    || !deliveryArea?.areaId
				default:
					return !preferredDeliveryPartner
			}
		})
	}, [tableData])

	const pickUpLocationOptions = useMemo(() => {
		const locationsMap = {}
		salesOrders.forEach(({ pickUpLocation: { label, address, id } }) => {
			locationsMap[id] = {
				label: label || address,
				value: id
			}
		})
		return Object.values(locationsMap)
	}, [salesOrders.length])

	const renderSelectedItems = () => {
		return selectedSalesOrderIds.length > 0 &&
			<div
				className={styles.selectRowsPrompt}
			>
				<Typography.Text>
					{`You have selected ${selectedSalesOrderIds.length} order(s).`}
				</Typography.Text>
				<span
					onClick={() => setSelectedSalesOrderIds([])}
					style={{ color: '#f5222d', fontWeight: 'bold', marginLeft: 6, cursor: 'pointer' }}
				>
					Clear all selections
				</span>
			</div>
	}

	const updateLastDeliveryAddressToDeliveryAreaMapping = (partnerType, locationId, deliveryArea = {}) => {
		const selectedDeliveryArea = convertDeliveryAreaToDeliveryAreaOption(deliveryArea, partnerType)
		setLastDeliveryAddressToDeliveryAreaMapping({
			...lastDeliveryAddressToDeliveryAreaMapping,
			[partnerType]: {
				...lastDeliveryAddressToDeliveryAreaMapping[partnerType] || {},
				[locationId]: selectedDeliveryArea
			}
		})
	}

	return (
		<Modal
			title='Update Delivery Partners'
			width='calc(100vw - 100px)'
			style={{ top: 20 }}
			destroyOnClose
			onCancel={onCancel}
			cancelButtonProps={modalButtonProps}
			visible={visible}
			okText='Update'
			onOk={updateOrdersDeliveryPartner}
			okButtonProps={{
				...modalButtonProps,
				disabled: isLoadingDeliveryPartners || isUpdateButtonDisabled,
				loading: isUpdating
			}}
		>
			<div className={styles.modal}>
				<div className={styles.modalHeader}>
					<div style={{ width: 300 }}>
						<CustomSelectSecondary
							title='Filter by Pickup Address'
							options={pickUpLocationOptions}
							value={selectedWarehouse}
							onChange={setSelectedWarehouse}
							defaultValue={[]}
							allowClear={true}
						/>
					</div>
					<div style={{ width: 300 }}>
						<CustomSelectSecondary
							title='Set Delivery Partner for Selected Orders'
							optionLabelProp='label'
							disabled={!selectedWarehouse}
							options={selectedWarehouseDeliveryPartnerOptions.map(({ id, name, type }) => ({ value: id, label: name, type }))}
							value={selectedDeliveryPartner}
							onChange={setSelectedDeliveryPartner}
							optionRender={DeliveryPartnerOption}
						/>
					</div>
				</div>
				<div className={styles.selectedRows}>
					{renderSelectedItems()}
				</div>
				<CustomTable
					tableLayout='auto'
					scroll={{ y: 'calc(100vh - 380px)' }}
					columns={orderColumns}
					dataSource={tableData}
					pagination={false}
					size='small'
					loading={isLoading}
					rowSelection={
						{
							preserveSelectedRowKeys: false,
							selectedRowKeys: selectedSalesOrderIds,
							getCheckboxProps: () => ({ disabled: !selectedWarehouse }),
							onChange: (selectedRowKeys) => setSelectedSalesOrderIds(selectedRowKeys)
						}
					}
				/>
			</div>
		</Modal>
	)
}

UpdateOrdersDeliveryPartnerModal.defaultProps = {
	onCancel: () => {},
	onComplete: () => {},
	salesOrderIds: [],
	visible: false
}

export default UpdateOrdersDeliveryPartnerModal
