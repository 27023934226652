import { getFirestore, collection, doc, onSnapshot, query, where, orderBy, limit, startAfter, getDocs, getDoc, updateDoc, writeBatch, Timestamp, setDoc, deleteDoc, and, or } from 'firebase/firestore'
import { getAuth, onIdTokenChanged } from 'firebase/auth'
import getFirebaseApp from '../../config/initFirebase'

const app = getFirebaseApp()
const auth = getAuth(app)
const db = getFirestore(app)

export const convertTimestamps = (data) => {
	if (data.createdAt instanceof Timestamp) {
		data.createdAt = data.createdAt.toDate()
	}
	if (data.updatedAt instanceof Timestamp) {
		data.updatedAt = data.updatedAt.toDate()
	}
	return data
}

export const Auth = {
	getCurrentUser: () => {
		return new Promise((resolve) => {
			const unsubscribe = onIdTokenChanged(auth, (authUser) => {
				resolve(authUser)
				unsubscribe()
			}, () => {
				resolve()
				unsubscribe()
			})
		})
	}
}

export const Notifications = {
	listenToNotificationCount: (uid, callback) => {
		const docRef = doc(db, 'user-notifications', uid)
		return onSnapshot(docRef, (docSnapshot) => {
			if (docSnapshot.exists()) {
				callback(docSnapshot.data())
			}
		})
	},
	fetchUnreadNotificationsOnce: async (uid, lastItemId, keys) => {
		const notificationsRef = collection(db, 'user-notifications', uid, 'notifications')
		let q = query(notificationsRef, where('isRead', '==', false), orderBy('createdAt', 'desc'), limit(50))
		if (keys) {
			if (keys.includes('sales-orders_sales_orders_unassigned')) {
				q = query(collection(db, 'user-notifications', uid, 'notifications'), or(
					where('key', 'in', keys),
					// This is essentially a `startsWith` query
					and(
						where('key', '>=', 'sales-orders_sales_orders_unassigned',),
						where('key', '<', 'sales-orders_sales_orders_unassignee')
					)
				))
			} else {
				q = query(q, where('key', 'in', keys))
			}
		}
		if (lastItemId) {
			const lastItemDoc = await getDoc(doc(notificationsRef, lastItemId))
			q = query(q, startAfter(lastItemDoc))
		}
		const snapshot = await getDocs(q)
		return snapshot.docs.map(doc => convertTimestamps(doc.data()))
	},
	fetchNotificationsOnce: async (uid, lastItemId, keys) => {
		const notificationsRef = collection(db, 'user-notifications', uid, 'notifications')
		let q = query(notificationsRef, orderBy('createdAt', 'desc'), limit(50))
		if (keys) {
			if (keys.includes('sales-orders_sales_orders_unassigned')) {
				q = query(collection(db, 'user-notifications', uid, 'notifications'), or(
					where('key', 'in', keys),
					// This is essentially a `startsWith` query
					and(
						where('key', '>=', 'sales-orders_sales_orders_unassigned',),
						where('key', '<', 'sales-orders_sales_orders_unassignede')
					)
				))
			} else {
				q = query(q, where('key', 'in', keys))
			}
		}
		if (lastItemId) {
			const lastItemDoc = await getDoc(doc(notificationsRef, lastItemId))
			q = query(q, startAfter(lastItemDoc))
		}
		const snapshot = await getDocs(q)
		return snapshot.docs.map(doc => convertTimestamps(doc.data()))
	},
	listenToUnreadNotifications: (uid, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'user-notifications', uid, 'notifications'), where('isRead', '==', false), orderBy('createdAt'), where('createdAt', '>', new Date()))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	},
	listenToNotifications: (uid, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'user-notifications', uid, 'notifications'), orderBy('createdAt'), where('createdAt', '>', new Date()))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	},
	readNotification: async (id) => {
		const user = await Auth.getCurrentUser()
		const notificationRef = doc(db, 'user-notifications', user.uid, 'notifications', id)
		await updateDoc(notificationRef, { isRead: true })
	},
	readNotificationsBulk: async (uid, notificationIds) => {
		const notificationsRef = collection(db, 'user-notifications', uid, 'notifications')
		const batch = writeBatch(db)
		notificationIds.forEach((id) => {
			const notificationDocRef = doc(notificationsRef, id)
			batch.update(notificationDocRef, { isRead: true })
		})
		await batch.commit()
	},
	setNewInsights: async (read) => {
		const user = await Auth.getCurrentUser()
		const docRef = doc(db, 'user-notifications', user.uid)
		await updateDoc(docRef, { newInsights: read })
	}
}

export const SalesOrders = {
	listenToSalesOrder: (id, onChange) => {
		const docRef = doc(db, 'sales-orders', id)
		return onSnapshot(docRef, { includeMetadataChanges: true }, (docSnapshot) => {
			onChange(docSnapshot.data())
		})
	},
	listenToSalesOrderHistory: (id, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'sales-orders-history', id, 'history'), orderBy('createdAt'))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	},
	fetchSaleOrderHistories: async (id) => {
		const q = query(collection(db, 'sales-orders-history', id, 'history'), orderBy('createdAt', 'desc'))
		const snapshot = await getDocs(q)
		return snapshot.docs.map(doc => convertTimestamps(doc.data()))
	},
	fetchSalesOrdersAdminHistoryOnce: async () => {
		const q = query(collection(db, 'sales-orders-admin-history'), orderBy('createdAt', 'desc'), limit(50))
		const snapshot = await getDocs(q)
		return snapshot.docs.map(doc => convertTimestamps(doc.data()))
	},
	fetchMoreSalesOrdersAdminHistory: async (lastDocumentId) => {
		const lastDocumentRef = doc(db, 'sales-orders-admin-history', lastDocumentId)
		const lastDocument = await getDoc(lastDocumentRef)
		const q = query(collection(db, 'sales-orders-admin-history'), orderBy('createdAt', 'desc'), startAfter(lastDocument), limit(50))
		const snapshot = await getDocs(q)
		return snapshot.docs.map(doc => convertTimestamps(doc.data()))
	}
}

export const DeliveryPartner = {
	listenToDeliveryPartnersHistory: (id, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'delivery-partners-history', id, 'history'), orderBy('createdAt'))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	}
}

export const DeliveryPlans = {
	listenToLoadingDeliveryPlans: (companyId, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'delivery-plans'), where('companyId', '==', companyId), where('loadState', '==', 'LOADING'))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	},
	listenToDeliveryPlans: (companyId, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'delivery-plans'), where('companyId', '==', companyId), where('createdAt', '>=', new Date()))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	},
	listenToDeliveryPlanHistory: (id, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'delivery-plans-history', id, 'history'), orderBy('createdAt'))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	},
	fetchDeliveryPlanHistories: async (id) => {
		const q = query(collection(db, 'delivery-plans-history', id, 'history'))
		const snapshot = await getDocs(q)
		return snapshot.docs.map(doc => convertTimestamps(doc.data()))
	},
	listenToDeliveryPlanItems: (deliveryPlanId, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'delivery-plan-items'), where('deliveryPlanId', '==', deliveryPlanId))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	}
}

export const Companies = {
	listenToCompany: (companyId, onChange) => {
		const docRef = doc(db, 'companies', companyId)
		return onSnapshot(docRef, { includeMetadataChanges: true }, (docSnapshot) => {
			onChange(docSnapshot.data())
		})
	},
	listenToCompanyHistory: (id, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'companies', id, 'history'), orderBy('createdAt'))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	}
}

export const Tasks = {
	listenToTask: (companyId, taskId, onChange) => {
		const docRef = doc(db, 'tasks', companyId, 'tasks', taskId)
		return onSnapshot(docRef, { includeMetadataChanges: true }, (docSnapshot) => {
			onChange(docSnapshot.data())
		})
	},
	listenToTasks: (companyId, taskName, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'tasks', companyId, 'tasks'), where('name', '==', taskName), where('loadState', '==', 'LOADING'))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	},
	fetchTaskOnce: async (companyId, taskId) => {
		const docRef = doc(db, 'tasks', companyId, 'tasks', taskId)
		const snapshot = await getDoc(docRef)
		return snapshot.data()
	}
}

export const Locations = {
	listenToUserLocation: (uid, trip, onCreated) => {
		const q = query(collection(db, 'user-locations', uid, 'locations'), where('tripId', '==', trip.id), where('createdAt', '>', new Date()), orderBy('createdAt', 'asc'))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()), trip)
				}
			})
		})
	},
	fetchUserLocationsOnce: async (uid, tripId) => {
		const q = query(collection(db, 'user-locations', uid, 'locations'), where('tripId', '==', tripId), orderBy('createdAt', 'asc'))
		const snapshot = await getDocs(q)
		return snapshot.docs.map(doc => convertTimestamps(doc.data()))
	}
}

export const Vehicles = {
	listenToVehicles: (companyId, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'vehicles'), where('companyId', '==', companyId))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	},
	listenToVehiclesByIds: (ids, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'vehicles'), where('id', 'in', ids))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	}
}

export const Refuels = {
	listenToRefuelHistory: (id, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'refuels-history', id, 'history'), orderBy('createdAt'))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	}
}

export const InventoryProductItems = {
	listenToInventoryProductItems: (companyId, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'inventory-product-items'), where('companyId', '==', companyId), where('updatedAt', '>=', new Date()))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	},
	fetchInventoryProductItemHistoryOnce: async (cid, changeState) => {
		let q = query(collection(db, 'inventory-product-items-history', cid, 'history'))
		if (changeState) {
			q = query(q, where('change', '==', changeState))
		}
		q = query(q, orderBy('updatedAt', 'desc'), limit(50))
		const snapshot = await getDocs(q)
		return snapshot.docs.map(doc => convertTimestamps(doc.data()))
	},
	fetchMoreInventoryProductItemHistory: async (cid, lastDocumentId, changeState) => {
		const lastDocumentRef = doc(db, 'inventory-product-items-history', cid, 'history', lastDocumentId)
		const lastDocument = await getDoc(lastDocumentRef)
		let q = query(collection(db, 'inventory-product-items-history', cid, 'history'))
		if (changeState) {
			q = query(q, where('change', '==', changeState))
		}
		q = query(q, orderBy('updatedAt', 'desc'), startAfter(lastDocument), limit(50))
		const snapshot = await getDocs(q)
		return snapshot.docs.map(doc => convertTimestamps(doc.data()))
	},
	listenToInventoryProductItemHistory: (cid, changeState, onCreated, onUpdated, onRemoved) => {
		let collectionRef = collection(db, 'inventory-product-items-history', cid, 'history')
		if (changeState) {
			collectionRef = query(collectionRef, where('change', '==', changeState))
		}
		const q = query(collectionRef, orderBy('updatedAt'), where('updatedAt', '>', new Date()))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	}
}

export const Invoices = {
	setInvoiceConfiguration: async (cid, data) => {
		const docRef = doc(db, 'invoice-configurations', cid)
		await setDoc(docRef, data, { merge: true })
	},
	listenToInvoiceConfigurationOnce: async (cid) => {
		const docRef = doc(db, 'invoice-configurations', cid)
		const snapshot = await getDoc(docRef)
		return snapshot.data()
	}
}

export const PurchaseOrders = {
	listenToPurchaseOrderHistory: (id, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'purchase-orders-history', id, 'history'), orderBy('createdAt'))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	}
}

export const Distributors = {
	fetchDistributorRegions: async (cid) => {
		const docRef = doc(db, 'distributor-regions', cid)
		const snapshot = await getDoc(docRef)
		return snapshot.data()
	},
	fetchSmsLog: async (id) => {
		const q = query(collection(db, 'distributors', id, 'sms'), orderBy('createdAt', 'desc'))
		const snapshot = await getDocs(q)
		return snapshot.docs.map(
			doc => convertTimestamps(doc.data()))
	},
	listenToSmsLogs: (id, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'distributors', id, 'sms'), orderBy('createdAt', 'desc'))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	},
	listenToDistributorHistory: (id, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'distributors-history', id, 'history'), orderBy('createdAt'))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	},
	fetchDistributorHistories: async (id) => {
		const q = query(collection(db, 'distributors-history', id, 'history'))
		const snapshot = await getDocs(q)
		return snapshot.docs.map(doc => convertTimestamps(doc.data()))
	}
}

export const Products = {
	listenToProductHistory: (id, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'products-history', id, 'history'), orderBy('createdAt'))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	},
	fetchProductHistories: async (id) => {
		const q = query(collection(db, 'products-history', id, 'history'))
		const snapshot = await getDocs(q)
		return snapshot.docs.map(doc => convertTimestamps(doc.data()))
	}
}

export const Expenses = {
	listenToExpenseHistory: (id, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'expenses-history', id, 'history'), orderBy('createdAt'))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	},
	fetchExpenseHistories: async (id) => {
		const q = query(collection(db, 'expenses-history', id, 'history'))
		const snapshot = await getDocs(q)
		return snapshot.docs.map(doc => convertTimestamps(doc.data()))
	}
}

export const Forecasts = {
	listenToForecastGenerationHistory: (cid, onChange) => {
		const docRef = doc(db, 'forecasts', cid, 'order-forecasts', 'status')
		return onSnapshot(docRef, { includeMetadataChanges: true }, (docSnapshot) => {
			if (docSnapshot.exists()) {
				onChange(docSnapshot.data())
			}
		})
	}
}

export const Sms = {
	fetchAvailableBalance: async (cid) => {
		const docRef = doc(db, 'sms-balances', cid)
		const snapshot = await getDoc(docRef)
		return snapshot.data()?.balance
	}
}

export const Ccl = {
	fetchAvailableBalance: async (cid) => {
		const docRef = doc(db, 'ccl-balances', cid)
		const snapshot = await getDoc(docRef)
		return snapshot.data()?.balance
	}
}

export const Presence = {
	listenToPresence: (cid, pageName, onChanged) => {
		const collectionRef = collection(db, 'presence', cid, pageName)
		return onSnapshot(collectionRef, (querySnapshot) => {
			if (onChanged) {
				const users = []
				querySnapshot.forEach((doc) => {
					users.push(doc.data())
				})
				onChanged(users)
			}
		})
	},
	setPresence: (cid, pageName, data) => {
		const docRef = doc(db, 'presence', cid, pageName, data.uid)
		return setDoc(docRef, data, { merge: true })
	},
	removePresence: async (cid, pageName, uid) => {
		const docRef = doc(db, 'presence', cid, pageName, uid)
		return deleteDoc(docRef).catch((e) => {
			console.error(e)
		})
	}
}

export const BillingInvoices = {
	listenToBillingInvoiceHistory: (id, onCreated, onUpdated, onRemoved) => {
		const q = query(collection(db, 'invoices', id, 'history'), orderBy('createdAt'))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'added' && onCreated) {
					onCreated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
				if (change.type === 'removed' && onRemoved) {
					onRemoved(convertTimestamps(change.doc.data()))
				}
			})
		})
	},
	listenToBillingInvoices: (invoiceIds, onUpdated) => {
		const q = query(collection(db, 'invoices'), where('id', 'in', invoiceIds))
		return onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach(change => {
				if (change.type === 'modified' && onUpdated) {
					onUpdated(convertTimestamps(change.doc.data()))
				}
			})
		})
	}
}
