import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { Button, Checkbox, Collapse, Modal, Radio, Switch, Typography, notification } from 'antd'
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import styles from './SalesOrdersPreferenceModal.module.css'
import { updatePreferences } from '../../store/companies/actions'
import { mapPreferenceStateToKey } from '../../utils/preferences'
import { fetchUserProfile } from '../../store/auth/actions'
import { dateTimeDisplayFormats, inventoryCheckModes, productListViewModes, productQuantityInputFormats, productUpsellModes, viewLocationTagModes } from '../../utils/constants'

const { Panel } = Collapse
const { Group } = Radio
const { Text } = Typography

const SalesOrdersPreferenceModal = ({
	visible,
	onCancel
}) => {
	const dispatch = useDispatch()
	const { permissions, companyId, preferences } = useSelector(state => state.authReducer)
	const [isDefaultShippingDateEnabled, setIsDefaultShippingDateEnabled] = useState(preferences.isDefaultShippingDateEnabled)
	const [isDuplicatePendingOrderCheckEnabled, setIsDuplicatePendingCheckEnabled] = useState(preferences.isDuplicatePendingOrderCheckEnabled)
	const [isDuplicateProcessingOrderCheckEnabled, setIsDuplicateProcessingOrderCheckEnabled] = useState(preferences.isDuplicateProcessingOrderCheckEnabled)
	const [dateTimeDisplayFormat, setDateTimeDisplayFormat] = useState(preferences.dateTimeDisplayFormat)
	const [productQuantityInputFormat, setProductQuantityInputFormat] = useState(preferences.productQuantityInputFormat)
	const [productUpsellMode, setProductUpsellMode] = useState(preferences.productUpsellMode)
	const [productListViewMode, setProductListViewMode] = useState(preferences.productListViewMode)
	const [isFastActionInTransitEnabled, setIsFastActionInTransitEnabled] = useState(preferences.isFastActionInTransitEnabled)
	const [isFastActionInTransitFromApprovedEnabled, setIsFastActionInTransitFromApprovedEnabled] = useState(preferences.isFastActionInTransitFromApprovedEnabled)
	const [isFastActionInTransitFromProcessingEnabled, setIsFastActionInTransitFromProcessingEnabled] = useState(preferences.isFastActionInTransitFromProcessingEnabled)
	const [isFastActionInTransitFromShippedEnabled, setIsFastActionInTransitFromShippedEnabled] = useState(preferences.isFastActionInTransitFromShippedEnabled)
	const [isFastActionApprovedEnabled, setIsFastActionApprovedEnabled] = useState(preferences.isFastActionApprovedEnabled)
	const [fastActionApprovedInventoryMode, setFastActionApprovedInventoryMode] = useState(preferences.fastActionApprovedInventoryMode)
	const [isFastActionDeliveredPaymentCollectedEnabled, setIsFastActionDeliveredPaymentCollectedEnabled] = useState(preferences.isFastActionDeliveredPaymentCollectedEnabled)
	const [viewLocationTagsMode, setViewLocationTagsMode] = useState(preferences.viewLocationTagsMode)
	const [viewLocationTagsApprovedEnabled, setViewLocationTagsApprovedEnabled] = useState(preferences.viewLocationTagsApprovedEnabled)
	const [viewLocationTagsProcessingEnabled, setViewLocationTagsProcessingEnabled] = useState(preferences.viewLocationTagsProcessingEnabled)
	const [viewLocationTagsShippedEnabled, setViewLocationTagsShippedEnabled] = useState(preferences.viewLocationTagsShippedEnabled)
	const [viewDeliveryPartnerTags, setViewDeliveryPartnerTags] = useState(preferences.viewDeliveryPartnerTags)
	const [viewCancelReasonTags, setViewCancelReasonTags] = useState(preferences.viewCancelReasonTags)
	const [assignBranchToOrderEnabled, setAssignBranchToOrderEnabled] = useState(preferences.assignBranchToOrderEnabled)
	const [filterOrdersByBranchEnabled, setFilterOrdersByBranchEnabled] = useState(preferences.filterOrderByBranchEnabled)
	const [websiteOrdersDeliveryFeeEnabled, setWebsiteOrdersDeliveryFeeEnabled] = useState(preferences.websiteOrdersDeliveryFeeEnabled)
	const [manualOrdersDeliveryFeeEnabled, setManualOrdersDeliveryFeeEnabled] = useState(preferences.manualOrdersDeliveryFeeEnabled)
	const [isSaving, setIsSaving] = useState(false)
	const isModified = useMemo(() => {
		if (!isDuplicateProcessingOrderCheckEnabled && !isDuplicatePendingOrderCheckEnabled) {
			return false
		}
		return (
			isDefaultShippingDateEnabled !== preferences.isDefaultShippingDateEnabled ||
			isDuplicatePendingOrderCheckEnabled !== preferences.isDuplicatePendingOrderCheckEnabled ||
			isDuplicateProcessingOrderCheckEnabled !== preferences.isDuplicateProcessingOrderCheckEnabled ||
			dateTimeDisplayFormat !== preferences.dateTimeDisplayFormat ||
			productQuantityInputFormat !== preferences.productQuantityInputFormat ||
            productUpsellMode !== preferences.productUpsellMode ||
            productListViewMode !== preferences.productListViewMode ||
            isFastActionInTransitEnabled !== preferences.isFastActionInTransitEnabled ||
            isFastActionInTransitFromApprovedEnabled !== preferences.isFastActionInTransitFromApprovedEnabled ||
            isFastActionInTransitFromProcessingEnabled !== preferences.isFastActionInTransitFromProcessingEnabled ||
            isFastActionInTransitFromShippedEnabled !== preferences.isFastActionInTransitFromShippedEnabled ||
            isFastActionApprovedEnabled !== preferences.isFastActionApprovedEnabled ||
            fastActionApprovedInventoryMode !== preferences.fastActionApprovedInventoryMode ||
            isFastActionDeliveredPaymentCollectedEnabled !== preferences.isFastActionDeliveredPaymentCollectedEnabled ||
            viewLocationTagsMode !== preferences.viewLocationTagsMode ||
            viewLocationTagsApprovedEnabled !== preferences.viewLocationTagsApprovedEnabled ||
            viewLocationTagsProcessingEnabled !== preferences.viewLocationTagsProcessingEnabled ||
            viewLocationTagsShippedEnabled !== preferences.viewLocationTagsShippedEnabled ||
            viewDeliveryPartnerTags !== preferences.viewDeliveryPartnerTags ||
            viewCancelReasonTags !== preferences.viewCancelReasonTags ||
            assignBranchToOrderEnabled !== preferences.assignBranchToOrderEnabled ||
            filterOrdersByBranchEnabled !== preferences.filterOrderByBranchEnabled ||
            websiteOrdersDeliveryFeeEnabled !== preferences.websiteOrdersDeliveryFeeEnabled ||
            manualOrdersDeliveryFeeEnabled !== preferences.manualOrdersDeliveryFeeEnabled
		)
	}, [
		preferences,
		isDefaultShippingDateEnabled,
		isDuplicatePendingOrderCheckEnabled,
		isDuplicateProcessingOrderCheckEnabled,
		dateTimeDisplayFormat,
		productQuantityInputFormat,
		productUpsellMode,
		productListViewMode,
		isFastActionInTransitEnabled,
		isFastActionInTransitFromApprovedEnabled,
		isFastActionInTransitFromProcessingEnabled,
		isFastActionInTransitFromShippedEnabled,
		isFastActionApprovedEnabled,
		fastActionApprovedInventoryMode,
		isFastActionDeliveredPaymentCollectedEnabled,
		viewLocationTagsMode,
		viewLocationTagsApprovedEnabled,
		viewLocationTagsProcessingEnabled,
		viewLocationTagsShippedEnabled,
		viewDeliveryPartnerTags,
		viewCancelReasonTags,
		assignBranchToOrderEnabled,
		filterOrdersByBranchEnabled,
		websiteOrdersDeliveryFeeEnabled,
		manualOrdersDeliveryFeeEnabled
	])

	useEffect(() => {
		if (preferences) {
			setIsDefaultShippingDateEnabled(preferences.isDefaultShippingDateEnabled)
			setIsDuplicatePendingCheckEnabled(preferences.isDuplicatePendingOrderCheckEnabled)
			setIsDuplicateProcessingOrderCheckEnabled(preferences.isDuplicateProcessingOrderCheckEnabled)
			setDateTimeDisplayFormat(preferences.dateTimeDisplayFormat)
			setProductQuantityInputFormat(preferences.productQuantityInputFormat)
			setProductUpsellMode(preferences.productUpsellMode)
			setProductListViewMode(preferences.productListViewMode)
			setIsFastActionInTransitEnabled(preferences.isFastActionInTransitEnabled)
			setIsFastActionInTransitFromApprovedEnabled(preferences.isFastActionInTransitFromApprovedEnabled)
			setIsFastActionInTransitFromProcessingEnabled(preferences.isFastActionInTransitFromProcessingEnabled)
			setIsFastActionInTransitFromShippedEnabled(preferences.isFastActionInTransitFromShippedEnabled)
			setIsFastActionApprovedEnabled(preferences.isFastActionApprovedEnabled)
			setFastActionApprovedInventoryMode(preferences.fastActionApprovedInventoryMode)
			setIsFastActionDeliveredPaymentCollectedEnabled(preferences.isFastActionDeliveredPaymentCollectedEnabled)
			setViewLocationTagsMode(preferences.viewLocationTagsMode)
			setViewLocationTagsApprovedEnabled(preferences.viewLocationTagsApprovedEnabled)
			setViewLocationTagsProcessingEnabled(preferences.viewLocationTagsProcessingEnabled)
			setViewLocationTagsShippedEnabled(preferences.viewLocationTagsShippedEnabled)
			setViewDeliveryPartnerTags(preferences.viewDeliveryPartnerTags)
			setViewCancelReasonTags(preferences.viewCancelReasonTags)
			setAssignBranchToOrderEnabled(preferences.assignBranchToOrderEnabled)
			setFilterOrdersByBranchEnabled(preferences.filterOrderByBranchEnabled)
			setWebsiteOrdersDeliveryFeeEnabled(preferences.websiteOrdersDeliveryFeeEnabled)
			setManualOrdersDeliveryFeeEnabled(preferences.manualOrdersDeliveryFeeEnabled)
		}
	}, [preferences])

	const onSave = async () => {
		if (!permissions.editOrderPreference) {
			notification.error({
				message: 'Unable to Save Preferences',
				description: 'Permission denied. Please contact administration and try again.',
				placement: 'bottomLeft'
			})
			return
		}
		if (isFastActionInTransitEnabled && !isFastActionInTransitFromApprovedEnabled && !isFastActionInTransitFromProcessingEnabled && !isFastActionInTransitFromShippedEnabled) {
			notification.error({
				message: 'Unable to Save Preferences',
				description: 'At least one status must be selected to enable Fast Action to In-Transit Orders.',
				placement: 'bottomLeft'
			})
			return
		}
		if (viewLocationTagsMode !== viewLocationTagModes.NONE && !viewLocationTagsApprovedEnabled && !viewLocationTagsProcessingEnabled && !viewLocationTagsShippedEnabled) {
			notification.error({
				message: 'Unable to Save Preferences',
				description: 'At least one status must be selected to enable View Location Tags.',
				placement: 'bottomLeft'
			})
			return
		}
		setIsSaving(true)
		try {
			const preferencesToCheck = [
				{ key: 'isDefaultShippingDateEnabled', value: isDefaultShippingDateEnabled },
				{ key: 'isDuplicatePendingOrderCheckEnabled', value: isDuplicatePendingOrderCheckEnabled },
				{ key: 'isDuplicateProcessingOrderCheckEnabled', value: isDuplicateProcessingOrderCheckEnabled },
				{ key: 'dateTimeDisplayFormat', value: dateTimeDisplayFormat },
				{ key: 'productQuantityInputFormat', value: productQuantityInputFormat },
				{ key: 'productUpsellMode', value: productUpsellMode },
				{ key: 'productListViewMode', value: productListViewMode },
				{ key: 'isFastActionInTransitEnabled', value: isFastActionInTransitEnabled },
				{ key: 'isFastActionInTransitFromApprovedEnabled', value: isFastActionInTransitFromApprovedEnabled },
				{ key: 'isFastActionInTransitFromProcessingEnabled', value: isFastActionInTransitFromProcessingEnabled },
				{ key: 'isFastActionInTransitFromShippedEnabled', value: isFastActionInTransitFromShippedEnabled },
				{ key: 'isFastActionApprovedEnabled', value: isFastActionApprovedEnabled },
				{ key: 'fastActionApprovedInventoryMode', value: fastActionApprovedInventoryMode },
				{ key: 'isFastActionDeliveredPaymentCollectedEnabled', value: isFastActionDeliveredPaymentCollectedEnabled },
				{ key: 'viewLocationTagsMode', value: viewLocationTagsMode },
				{ key: 'viewLocationTagsApprovedEnabled', value: viewLocationTagsApprovedEnabled },
				{ key: 'viewLocationTagsProcessingEnabled', value: viewLocationTagsProcessingEnabled },
				{ key: 'viewLocationTagsShippedEnabled', value: viewLocationTagsShippedEnabled },
				{ key: 'viewDeliveryPartnerTags', value: viewDeliveryPartnerTags },
				{ key: 'viewCancelReasonTags', value: viewCancelReasonTags },
				{ key: 'assignBranchToOrderEnabled', value: assignBranchToOrderEnabled },
				{ key: 'filterOrderByBranchEnabled', value: filterOrdersByBranchEnabled },
				{ key: 'websiteOrdersDeliveryFeeEnabled', value: websiteOrdersDeliveryFeeEnabled },
				{ key: 'manualOrdersDeliveryFeeEnabled', value: manualOrdersDeliveryFeeEnabled }
			]
			const newPreferences = preferencesToCheck
				.filter(pref => pref.value !== preferences[pref.key])
				.map(pref => ({
					key: mapPreferenceStateToKey(pref.key),
					value: pref.value
				}))

			await dispatch(updatePreferences(
				companyId,
				{
					preferences: newPreferences
				}
			))
			await dispatch(fetchUserProfile())
			notification.success({
				description: 'Preferences has been saved successfully',
				message: 'Success',
				placement: 'bottomLeft'
			})
		} catch (e) {
			setIsDefaultShippingDateEnabled(preferences.isDefaultShippingDateEnabled)
			setIsDuplicatePendingCheckEnabled(preferences.isDuplicatePendingOrderCheckEnabled)
			setIsDuplicateProcessingOrderCheckEnabled(preferences.isDuplicateProcessingOrderCheckEnabled)
			setDateTimeDisplayFormat(preferences.dateTimeDisplayFormat)
			setProductQuantityInputFormat(preferences.productQuantityInputFormat)
			setProductUpsellMode(preferences.productUpsellMode)
			setProductListViewMode(preferences.productListViewMode)
			setIsFastActionInTransitEnabled(preferences.isFastActionInTransitEnabled)
			setIsFastActionInTransitFromApprovedEnabled(preferences.isFastActionInTransitFromApprovedEnabled)
			setIsFastActionInTransitFromProcessingEnabled(preferences.isFastActionInTransitFromProcessingEnabled)
			setIsFastActionInTransitFromShippedEnabled(preferences.isFastActionInTransitFromShippedEnabled)
			setIsFastActionApprovedEnabled(preferences.isFastActionApprovedEnabled)
			setFastActionApprovedInventoryMode(preferences.fastActionApprovedInventoryMode)
			setIsFastActionDeliveredPaymentCollectedEnabled(preferences.isFastActionDeliveredPaymentCollectedEnabled)
			setViewLocationTagsMode(preferences.viewLocationTagsMode)
			setViewLocationTagsApprovedEnabled(preferences.viewLocationTagsApprovedEnabled)
			setViewLocationTagsProcessingEnabled(preferences.viewLocationTagsProcessingEnabled)
			setViewLocationTagsShippedEnabled(preferences.viewLocationTagsShippedEnabled)
			setViewDeliveryPartnerTags(preferences.viewDeliveryPartnerTags)
			setViewCancelReasonTags(preferences.viewCancelReasonTags)
			setAssignBranchToOrderEnabled(preferences.assignBranchToOrderEnabled)
			setFilterOrdersByBranchEnabled(preferences.filterOrderByBranchEnabled)
			setWebsiteOrdersDeliveryFeeEnabled(preferences.websiteOrdersDeliveryFeeEnabled)
			setManualOrdersDeliveryFeeEnabled(preferences.manualOrdersDeliveryFeeEnabled)
			notification.error({
				message: 'Unable to Save Preferences',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			onCancel()
			setIsSaving(false)
		}
	}

	return (
		<Modal
			title='Preferences'
			visible={visible}
			onCancel={onCancel}
			width={1000}
			footer={[
				<Button
					key={1}
					disabled={false}
					onClick={onCancel}
				>
					Cancel
				</Button>,
				<Button
					key={2}
					type='primary'
					disabled={!isModified || !permissions.editOrderPreference}
					onClick={onSave}
					loading={isSaving}
				>
					Save
				</Button>
			]}
		>
			<div className={styles.container}>
				<Collapse
					ghost={true}
					expandIcon={({ isActive }) => isActive ? <DownOutlined /> : <RightOutlined />}
					style={{ fontSize: '18px' }}
				>
					<Panel
						header='Date Display Preference'
						key='1'
					>
						<div className={styles.infoText}>
                        Determine how the Date is shown on the Order module. If the Date is selected only the Date will be displayed. If the Date & time are selected, the time will be displayed along with the date.
						</div>
						<div className={styles.dateTimePreference}>
							<Text strong={true} style={{ fontSize: '16px' }}>
								Date/Time Format:
							</Text>
							<Group
								value={dateTimeDisplayFormat}
								onChange={e => setDateTimeDisplayFormat(e.target.value)}
							>
								<Radio value={dateTimeDisplayFormats.DATE}>Date</Radio>
								<Radio value={dateTimeDisplayFormats.DATE_TIME}>Date & Time</Radio>
							</Group>
						</div>
					</Panel>
				</Collapse>
				<Collapse
					ghost={true}
					expandIcon={({ isActive }) => isActive ? <DownOutlined /> : <RightOutlined />}
					style={{ fontSize: 18 }}
				>
					<Panel
						header='Order Duplication Preference'
						key='1'
					>
						<div className={styles.infoText}>
                        Determine in which statuses you want to find the duplicate orders. The system assures duplicate order detection on the selected status based on the selection.
						</div>
						<div style={{ display: 'flex', gap: 16 }}>
							<Checkbox
								checked={isDuplicatePendingOrderCheckEnabled}
								onChange={e => setIsDuplicatePendingCheckEnabled(e.target.checked)}
							>
								Pending Order
							</Checkbox>
							<Checkbox
								checked={isDuplicateProcessingOrderCheckEnabled}
								onChange={e => setIsDuplicateProcessingOrderCheckEnabled(e.target.checked)}
							>
								Processing Order
							</Checkbox>
						</div>
					</Panel>
					<Panel
						header='Checkout Preference'
						key='2'
					>
						<div className={styles.infoText}>
                        Determine if you want to prefill the shipping date to the current date. If the Default Shipping Date is selected, the current date will be auto-filled as the order&apos;s shipping date.
						</div>
						<Checkbox
							checked={isDefaultShippingDateEnabled}
							onChange={e => setIsDefaultShippingDateEnabled(e.target.checked)}
						>
							Default Shipping Date
						</Checkbox>
					</Panel>
					<Panel
						header='Product Quantity Input Preference'
						key='3'
					>
						<div className={styles.infoText}>
                        Determine how you want to input product quantity. If the integer is selected you will be able to input the Integer values of the product quantity for creating orders. If Fractional is selected you will be able to input fractional values as the product quantity and also with integer values for creating orders. Note that Orders with fractional quantities won&apos;t be placed in the Delivery Partner merchant panel due to API limitations.
						</div>
						<div className={styles.dateTimePreference}>
							<Group
								value={productQuantityInputFormat}
								onChange={e => setProductQuantityInputFormat(e.target.value)}
							>
								<Radio value={productQuantityInputFormats.INTEGER}>Integer</Radio>
								<Radio value={productQuantityInputFormats.FRACTION}>Fraction</Radio>
							</Group>
						</div>
					</Panel>
					<Panel
						header='Product Upsell Preference'
						key='4'
					>
						<div className={styles.infoText}>
                        Determine when you want to make the product upsell button visible. Based on the selection, the Product upsell button will be visible and you will be able to create or edit orders by upselling any product.
						</div>
						<div className={styles.dateTimePreference}>
							<Group
								value={productUpsellMode}
								onChange={e => setProductUpsellMode(e.target.value)}
							>
								<Radio value={productUpsellModes.NONE}>None</Radio>
								<Radio value={productUpsellModes.CREATE_ORDER}>Create Order</Radio>
								<Radio value={productUpsellModes.EDIT_ORDER}>Edit Order</Radio>
								<Radio value={productUpsellModes.BOTH}>Both</Radio>
							</Group>
						</div>
					</Panel>
					<Panel
						header='Product List Viewing Preference'
						key='5'
					>
						<div className={styles.infoText}>
                            Determine how the product will be displayed on the order creation and editing journey. If Grouped by Variant is selected, all the variants of the base product will be displayed on a single card. If the Individual variant is selected, all the variants will be displayed on the individual card.
						</div>
						<div className={styles.dateTimePreference}>
							<Group
								value={productListViewMode}
								onChange={e => setProductListViewMode(e.target.value)}
							>
								<Radio value={productListViewModes.GROUPED_VARIANT}>Grouped By Variant</Radio>
								<Radio value={productListViewModes.INDIVIDUAL_VARIANT}>Individual Variant</Radio>
							</Group>
						</div>
					</Panel>
					<Panel
						header='Fast Action Preference'
						key='6'
					>
						<h5>Fast Action to In-Transit Orders</h5>
						<div className={styles.infoText}>
                            Determine If you want to send the selected orders to Courier in just one click. By enabling this preference you will find the Fast Action button to send the selected order to their assigned integrated delivery partners quickly. Select your desired order status to see the fast action button on the selected status.
						</div>
						<Switch
							style={{ marginLeft: 26, marginBottom: 12 }}
							checkedChildren='Enabled'
							unCheckedChildren='Disabled'
							checked={isFastActionInTransitEnabled}
							onChange={setIsFastActionInTransitEnabled}
						/>
						<div className={styles.dateTimePreference}>
							<div style={{ display: 'flex', gap: 16 }}>
								<Checkbox
									disabled={!isFastActionInTransitEnabled}
									checked={isFastActionInTransitFromApprovedEnabled}
									onChange={e => setIsFastActionInTransitFromApprovedEnabled(e.target.checked)}
								>
								Approved
								</Checkbox>
								<Checkbox
									disabled={!isFastActionInTransitEnabled}
									checked={isFastActionInTransitFromProcessingEnabled}
									onChange={e => setIsFastActionInTransitFromProcessingEnabled(e.target.checked)}
								>
								Processing
								</Checkbox>
								<Checkbox
									disabled={!isFastActionInTransitEnabled}
									checked={isFastActionInTransitFromShippedEnabled}
									onChange={e => setIsFastActionInTransitFromShippedEnabled(e.target.checked)}
								>
								Shipped
								</Checkbox>
							</div>
						</div>
						<h5 style={{ marginTop: 24 }}>Fast Action to Approve Orders</h5>
						<div className={styles.infoText}>
                            Determine If you want to Approve Order(s) in just one click. By enabling this preference you will find the Fast Action button to Approve Order(s) on the Pending Orders tab.
						</div>
						<Switch
							style={{ marginLeft: 26, marginBottom: 12 }}
							checkedChildren='Enabled'
							unCheckedChildren='Disabled'
							checked={isFastActionApprovedEnabled}
							onChange={setIsFastActionApprovedEnabled}
						/>
						<div className={styles.dateTimePreference}>
							<Group
								disabled={!isFastActionApprovedEnabled}
								value={fastActionApprovedInventoryMode}
								onChange={e => setFastActionApprovedInventoryMode(e.target.value)}
							>
								<Radio value={inventoryCheckModes.CHECK_INVENTORY}>Check Inventory Availability</Radio>
								<Radio value={inventoryCheckModes.SKIP_INVENTORY_CHECK}>Skip Inventory Checking</Radio>
							</Group>
						</div>
						<h5 style={{ marginTop: 24 }}>Fast Action to Deliver Payment Collected Orders</h5>
						<div className={styles.infoText}>
                            Determine If you want to Collect Payment for Delivered Order(s) in just one click. By enabling this preference you will find the Fast Action button to Payment Collected Order(s) on the Delivered Orders tab.
						</div>
						<Switch
							style={{ marginLeft: 26, marginBottom: 12 }}
							checkedChildren='Enabled'
							unCheckedChildren='Disabled'
							checked={isFastActionDeliveredPaymentCollectedEnabled}
							onChange={setIsFastActionDeliveredPaymentCollectedEnabled}
						/>
					</Panel>
					<Panel
						header='Order Tags Viewing Preference'
						key='7'
					>
						<h5>Location Tags Viewing Preference</h5>
						<div className={styles.infoText}>
                            Determine If you want to show/hide the location tags under the selected order status. By enabling this preference you will find the location tags by cities or divisions under your selected desired order status.
						</div>
						<Group
							style={{ marginBottom: 24 }}
							value={viewLocationTagsMode}
							onChange={e => setViewLocationTagsMode(e.target.value)}
						>
							<Radio value={viewLocationTagModes.NONE}>None</Radio>
							<Radio value={viewLocationTagModes.DISTRICT}>Cities</Radio>
							<Radio value={viewLocationTagModes.DIVISION}>Divisions</Radio>
						</Group>
						<div className={styles.dateTimePreference}>
							<div style={{ display: 'flex', gap: 16 }}>
								<Checkbox
									disabled={viewLocationTagsMode === viewLocationTagModes.NONE}
									checked={viewLocationTagsApprovedEnabled}
									onChange={e => setViewLocationTagsApprovedEnabled(e.target.checked)}
								>
								Approved
								</Checkbox>
								<Checkbox
									disabled={viewLocationTagsMode === viewLocationTagModes.NONE}
									checked={viewLocationTagsProcessingEnabled}
									onChange={e => setViewLocationTagsProcessingEnabled(e.target.checked)}
								>
								Processing
								</Checkbox>
								<Checkbox
									disabled={viewLocationTagsMode === viewLocationTagModes.NONE}
									checked={viewLocationTagsShippedEnabled}
									onChange={e => setViewLocationTagsShippedEnabled(e.target.checked)}
								>
								Shipped
								</Checkbox>
							</div>
						</div>
						<h5 style={{ marginTop: 24 }}>Delivery Partner Tags Viewing Preference</h5>
						<div className={styles.infoText}>
                            Determine If you want to show/hide the delivery partner tags under the In-Transit order status. By enabling this preference you will find the delivery partner tags under the In-Transit order status.
						</div>
						<Switch
							style={{ marginLeft: 26, marginBottom: 12 }}
							checkedChildren='Enabled'
							unCheckedChildren='Disabled'
							checked={viewDeliveryPartnerTags}
							onChange={setViewDeliveryPartnerTags}
						/>
						<h5 style={{ marginTop: 24 }}>Cancel Reasons Tags Viewing Preference</h5>
						<div className={styles.infoText}>
                            Determine If you want to show/hide the order cancel reasons tags under the Cancelled order status. By enabling this preference you will find the cancel reason tags under the Cancelled order status.
						</div>
						<Switch
							style={{ marginLeft: 26, marginBottom: 12 }}
							checkedChildren='Enabled'
							unCheckedChildren='Disabled'
							checked={viewCancelReasonTags}
							onChange={setViewCancelReasonTags}
						/>
					</Panel>
					<Panel
						header='Branch Preference'
						key='8'
					>
						<h5>Assign Branch to Orders</h5>
						<div className={styles.infoText}>
                            Determine if you want to assign a branch to the Orders. By enabling this preference you will find the branch selection functionality while creating and editing orders.
						</div>
						<Switch
							style={{ marginLeft: 26, marginBottom: 12 }}
							checkedChildren='Enabled'
							unCheckedChildren='Disabled'
							checked={assignBranchToOrderEnabled}
							onChange={setAssignBranchToOrderEnabled}
						/>
						<h5 style={{ marginTop: 24 }}>Filter Orders with Branches</h5>
						<div className={styles.infoText}>
                            Determine if you want to filter orders by Branches. By enabling this preference you will find the order filtering functionality with the Branches on the filter modal.
						</div>
						<Switch
							style={{ marginLeft: 26, marginBottom: 12 }}
							checkedChildren='Enabled'
							unCheckedChildren='Disabled'
							checked={filterOrdersByBranchEnabled}
							onChange={setFilterOrdersByBranchEnabled}
						/>
					</Panel>
					<Panel
						header='Orders Delivery Fee Preference'
						key='9'
					>
						<h5>Website Orders Delivery Fee Preference</h5>
						<div className={styles.infoText}>
                            Determine if you want to store the delivery charge obtained from the website. When enabled, the delivery charge should be calculated based on the charge set on the website, irrespective of the assigned delivery partner. When disabled, the delivery charge will be calculated based on the assigned delivery partner.
						</div>
						<Switch
							style={{ marginLeft: 26, marginBottom: 12 }}
							checkedChildren='Enabled'
							unCheckedChildren='Disabled'
							checked={websiteOrdersDeliveryFeeEnabled}
							onChange={setWebsiteOrdersDeliveryFeeEnabled}
						/>
						<h5 style={{ marginTop: 24 }}>Manually Created Orders Delivery Fee Preferences</h5>
						<div className={styles.infoText}>
                            Determine if you want to store the inputted delivery charge for manually created orders. When enabled, the delivery charge should be calculated based on the charge set by users, irrespective of the assigned delivery partner. When disabled, the delivery charge will be calculated based on the assigned delivery partner.
						</div>
						<Switch
							style={{ marginLeft: 26, marginBottom: 12 }}
							checkedChildren='Enabled'
							unCheckedChildren='Disabled'
							checked={manualOrdersDeliveryFeeEnabled}
							onChange={setManualOrdersDeliveryFeeEnabled}
						/>
					</Panel>
				</Collapse>
			</div>
		</Modal>
	)
}

SalesOrdersPreferenceModal.propTypes = {
	visible: PropTypes.bool,
	onCancel: PropTypes.func
}

SalesOrdersPreferenceModal.defaultProps = {
	visible: false,
	onCancel: () => {}
}

export default SalesOrdersPreferenceModal
